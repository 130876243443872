import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { captureMessage } from '@sentry/react';
import moment from 'moment';
import { applePayJs, PaymentStatus } from '../apple-pay-js';

import { ReduxState, useTypedDispatch } from '../../redux/store';
import {
  createMastercardSessionAction,
  createApplePaySessionAction,
  createApplePayPaymentAction,
  createHyperPayCheckoutAction,
} from '../../store_deprecated/actions/walletActions';
import {
  CreateApplePaySessionPayload,
  SaveSubscriptionPayload,
  WalletProvider,
} from '../../store_deprecated/types/walletTypes';
import { depositSelector } from '../../store_deprecated/selectors/walletSelectors';

import { saveSubscription } from '../../redux/modules/subscription/subscription.actions';
import { ApplePaymentType } from '../enums';
import { getCreatedSubscription, saveUserSubscription } from '../api_deprecated';
import { RecurringStatus } from '../../forms/fields/recurringPayment';

type AnbMastercardSession = {
  amount: number;
  merchant: string;
  orderId: string;
  returnUrl: string;
  sessionId: string;
};

type HyperPayCheckout = {
  id: string;
  result: {
    code: string;
    description: string;
  };
  buildNumber: string;
  timestamp: string;
  ndc: string;
};

export type UseHyperPayProps = {
  anbMastercardCheckoutBaseUrl?: string;
  anbMastercardCheckoutVersion?: string;
  anbMastercardCheckoutCancelCallBack?: string;
  page?: string;
  paddingTop?: number;
  paddingBottom?: number;
};

const useHyperPay = ({
  anbMastercardCheckoutBaseUrl,
  anbMastercardCheckoutVersion,
  anbMastercardCheckoutCancelCallBack = 'anbMastercardCheckoutCancelCallback',
  page,
}: UseHyperPayProps) => {
  const { t } = useTranslation();
  const { t: tApiError } = useTranslation('apiError');
  const dispatch = useTypedDispatch();
  const [isScriptLoaded, setIsScriptLoaded] = React.useState(false);
  const [showSuccessfulScreen, setShowSuccessfulScreen] = React.useState(false);
  const [showFailureScreen, setShowFailureScreen] = React.useState(false);
  const [showHyperPayProcessingScreen, setShowHyperPayProcessingScreen] = React.useState(false);

  const { backendProductId } = useSelector((state: ReduxState) => state.selectedCardToInvest);

  const [openDepositDialog, setOpenDepositDialog] = React.useState(false);

  const [resultIndicator, setResultIndicator] = React.useState<IAnyObject>();
  const [successIndicator, setSuccessIndicator] = React.useState<IAnyObject>();
  const [isApplePayAvailable, setIsApplePayAvailable] = React.useState(false);
  const isMasterCardAvailable = !!(anbMastercardCheckoutBaseUrl && anbMastercardCheckoutVersion);
  const [hyperPayCheckoutId, setHyperPayCheckoutId] = React.useState<string>('');
  const [error, setError] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const depositData = useSelector(depositSelector);

  const onDeposit = () => {
    setShowSuccessfulScreen(false);
    setShowHyperPayProcessingScreen(false);
    setShowFailureScreen(false);
    setOpenDepositDialog(true);
  };

  const closeDialog = () => {
    setOpenDepositDialog(false);
    // TODO: make sure what we should add here to close the dialog
  };

  const onSuccessButtonClick = () => {
    closeDialog();
    window.location.reload();
  };

  /**
   * Inject anb mastercard checkout script
   */
  const injectAnbMastercardCheckoutScript = () => {
    const script = document.createElement('script');

    script.id = 'anb-mastercard-checkout';
    script.src = `${anbMastercardCheckoutBaseUrl}/checkout/version/${anbMastercardCheckoutVersion}/checkout.js`;
    script.dataset.error = 'anbMastercardCheckoutErrorCallback';
    script.dataset.cancel = 'anbMastercardCheckoutCancelCallback';
    script.dataset.complete = 'anbMastercardCheckoutCompleteCallback';
    script.setAttributeNS(null, 'data-beforeRedirect', 'anbMastercardCheckoutBeforeRedirect');
    script.setAttributeNS(null, 'data-afterRedirect', 'anbMastercardCheckoutAfterRedirect');
    script.async = true;

    script.onload = () => {
      setIsScriptLoaded(true);
      console.log('Mastercard Checkout script loaded successfully.');
    };
    document.body.appendChild(script);
  };

  const checkoutConfigure = async (session: AnbMastercardSession) => {
    Checkout.configure({
      merchant: session.merchant,
      order: {
        description: 'Madkhol payment order goods',
      },
      session: {
        id: session.sessionId,
      },
      interaction: {
        merchant: {
          name: 'Madkhol',
          email: 'abadawy@madkhol.com',
          phone: '+966118352514',
          logo: 'https://app.madkol.co/images/madkhol.gif',
          url: 'https://madkhol.com',
          address: {
            line1: 'Dubai',
            line2: 'UAE',
          },
        },
        displayControl: {
          billingAddress: 'HIDE',
          customerEmail: 'HIDE',
          orderSummary: 'SHOW',
          paymentConfirmation: 'HIDE',
          paymentTerms: 'HIDE',
        },
      },
    });
  };

  const createAppleSession = async (payload: CreateApplePaySessionPayload) =>
    dispatch(createApplePaySessionAction(payload));

  const handleApplePayPayment = async (amount: number, paymentToken: any) => {
    await dispatch(
      createApplePayPaymentAction({
        currency: 'SAR',
        amount,
        paymentToken,
        walletProvider: WalletProvider.APPLE_PAY,
      }),
    );
  };

  const handleSaveSubscription =
    (
      amount: number,
      recurringPaymentEndDate: Date,
      recurringPaymentIntervalUnit: ApplePayJS.ApplePayRecurringPaymentDateUnit,
      recurringPaymentIntervalCount: number,
    ) =>
    async (token: string) => {
      await dispatch(
        saveSubscription({
          amount,
          endDate: recurringPaymentEndDate,
          intervalUnit: recurringPaymentIntervalUnit,
          intervalCount: recurringPaymentIntervalCount,
          merchantToken: token,
        }),
      );
    };

  const handleApplePayment = async (amount: number) => {
    try {
      console.log('[handleApplePayment]');

      const result = await applePayJs.performApplePayPayment({
        type: ApplePaymentType.SINGLE_PAYMENT,
        currencyCode: 'SAR',
        label: 'Deposit',
        items: [],
        amount,
        createAppleSessionFn: createAppleSession,
        handleApplePayPayment,
        productId: backendProductId,
      });

      captureMessage('Apple Pay payment result', {
        extra: {
          result,
          isUndefined: result === undefined,
          isSuccess: result === PaymentStatus.SUCCESS,
          isCancel: result === PaymentStatus.CANCEL,
          isFailure: result === PaymentStatus.FAILURE,
        },
      });

      // TODO: handle the result properly, code below is just for testing

      if (result === PaymentStatus.SUCCESS) {
        return true;
      }

      if (result === PaymentStatus.CANCEL) {
        return false;
      }

      if (result === PaymentStatus.FAILURE) {
        return false;
      }

      return false;
    } catch (err: any) {
      console.error('Apple Pay payment failed', err);
      return false;
    }
  };

  const handleRecurringApplePayment = async (amount: number) => {
    try {
      console.log('[handleRecurringApplePayment]');
      const endDate = moment().add(2, 'day').toDate();
      const intervalUnit = 'hour';
      const intervalCount = 20;

      const result = await applePayJs.performApplePayPayment({
        type: ApplePaymentType.RECURRING_PAYMENT,
        currencyCode: 'SAR',
        items: [],
        label: 'Subscription',
        amount,
        recurringPaymentEndDate: endDate,
        recurringPaymentIntervalUnit: intervalUnit,
        recurringPaymentIntervalCount: intervalCount,
        createAppleSessionFn: createAppleSession,
        handleApplePayPayment,
        saveSubscription: handleSaveSubscription(amount, endDate, intervalUnit, intervalCount),
      });
      if (result === PaymentStatus.SUCCESS) {
        return true;
      }

      if (result === PaymentStatus.CANCEL) {
        return false;
      }

      if (result === PaymentStatus.FAILURE) {
        return false;
      }

      return false;
    } catch (err: any) {
      console.error('Apple Pay payment failed', err);
      return false;
    }
  };

  const handleMastercardPayment = async (amount: number) => {
    const result = (await dispatch(
      createMastercardSessionAction({
        currency: 'SAR',
        amount,
        returnUrl: `${window.location.origin}/${page}`,
      }),
    )) as AnbMastercardSession;

    await checkoutConfigure(result);

    if (result) {
      Checkout.showLightbox();
    }
  };

  const handleHyperPayPayment = async (amount: number, paymentType: 'DB' | 'PA' | 'RG', brand: string) => {
    const result = (await dispatch(
      createHyperPayCheckoutAction({
        currency: 'SAR',
        amount,
        paymentType,
        brand,
        productId: backendProductId,
      }),
    )) as HyperPayCheckout;

    setHyperPayCheckoutId(result.id);
  };

  const addCardPreparation = async (amount: number, paymentBrand: 'MADA' | 'VISA') => {
    sessionStorage.setItem('hyperPayPaymentType', 'RG');
    setShowHyperPayProcessingScreen(true);

    const subscriptionData: any = await getCreatedSubscription();

    const payload: Partial<SaveSubscriptionPayload> = {
      endDate: new Date(),
      startDate: new Date(),
      frequencyUnit: 'weekly',
      endDateType: 'date',
      amount,
      dueDate: new Date(),
      paymentBrand,
      isPrimaryCard: false,
      status: RecurringStatus.CREATED,
      productId: backendProductId,
      ...(subscriptionData?.id ? { id: subscriptionData?.id } : {}),
    };

    const response: any = await saveUserSubscription(payload);

    sessionStorage.setItem('subscriptionId', response?.data?.id);

    await handleHyperPayPayment(amount, 'RG', paymentBrand);
  };

  const onCloseDepositDialog = async (
    provider:
      | 'card'
      | 'apple'
      | 'appleRecurring'
      | 'hyperPayMada'
      | 'hyperPayRecurring'
      | 'hyperPayVisaOrMaster'
      | 'hyperPayRegistration'
      | 'addCardHyperPayVisaOrMaster'
      | 'addCardHyperPayMada',
    depositAmount: number,
  ) => {
    if (!depositAmount) {
      setOpenDepositDialog(false);
      return;
    }

    try {
      setIsLoading(true);

      if (provider === 'card') {
        await handleMastercardPayment(depositAmount);
        closeDialog();
      }

      if (provider === 'apple') {
        const isSuccess = await handleApplePayment(depositAmount);
        if (isSuccess) {
          setShowSuccessfulScreen(true);
        } else {
          setShowFailureScreen(true);
        }
      }

      if (provider === 'appleRecurring') {
        const isSuccess = await handleRecurringApplePayment(depositAmount);
        if (isSuccess) {
          setShowSuccessfulScreen(true);
        } else {
          setShowFailureScreen(true);
        }
      }

      if (provider === 'addCardHyperPayMada') {
        addCardPreparation(depositAmount, 'MADA');
      }

      if (provider === 'addCardHyperPayVisaOrMaster') {
        addCardPreparation(depositAmount, 'VISA');
      }

      if (provider === 'hyperPayMada') {
        sessionStorage.setItem('hyperPayPaymentType', 'DB');
        setShowHyperPayProcessingScreen(true);
        await handleHyperPayPayment(depositAmount, 'DB', 'MADA');
      }

      if (provider === 'hyperPayRecurring') {
        sessionStorage.setItem('hyperPayPaymentType', 'PA');
        setShowHyperPayProcessingScreen(true);
        await handleHyperPayPayment(depositAmount, 'PA', 'MADA');
      }

      if (provider === 'hyperPayVisaOrMaster') {
        sessionStorage.setItem('hyperPayPaymentType', 'DB');
        setShowHyperPayProcessingScreen(true);
        await handleHyperPayPayment(depositAmount, 'DB', 'VISA');
      }

      if (provider === 'hyperPayRegistration') {
        sessionStorage.setItem('hyperPayPaymentType', 'RG');
        setShowHyperPayProcessingScreen(true);
        await handleHyperPayPayment(depositAmount, 'RG', 'MADA');
      }
    } catch (err: any) {
      closeDialog();
      console.log('[Deposit some error]', err);
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    const initialize = async () => {
      setIsApplePayAvailable(applePayJs.canMakePayments());
    };

    initialize();

    if (isMasterCardAvailable) {
      injectAnbMastercardCheckoutScript();

      /**
       * Create callback functions
       */

      window.anbMastercardCheckoutErrorCallback = (err: any) => {
        console.log('[anbMastercardCheckoutErrorCallback]', JSON.stringify(err));
        setError(JSON.stringify(err));
      };
      // @ts-ignore
      window[anbMastercardCheckoutCancelCallBack] = () => {
        console.log(`[${anbMastercardCheckoutCancelCallBack}]`);
        setError('ANB_MASTERCARD_DEPOSIT_CANCELED');
      };
      window.anbMastercardCheckoutCompleteCallback = (response: IAnyObject) => {
        console.log(
          '[anbMastercardCheckoutCompleteCallback]',
          response,
          resultIndicator === successIndicator ? 'SUCCESS' : 'ERROR',
        );
        // Save the resultIndicator
        setResultIndicator(response);
        setOpenDepositDialog(true);
        setShowSuccessfulScreen(true);
        // TODO: implement error handling
      };
      window.anbMastercardCheckoutBeforeRedirect = () => {
        console.log('[anbMastercardCheckoutBeforeRedirect]');

        return { successIndicator };
      };
      window.anbMastercardCheckoutAfterRedirect = (data: IAnyObject) => {
        // Compare with the resultIndicator saved in the completeCallback() method
        if (successIndicator) {
          const result = successIndicator === data.successIndicator ? 'SUCCESS' : 'ERROR';
        } else {
          setSuccessIndicator(data.successIndicator);
        }
      };

      return () => {
        document.getElementById('anb-mastercard-checkout')?.remove();
        window.anbMastercardCheckoutErrorCallback = undefined;
        // @ts-ignore
        window[anbMastercardCheckoutCancelCallBack] = undefined;
        window.anbMastercardCheckoutBeforeRedirect = undefined;
        window.anbMastercardCheckoutAfterRedirect = undefined;
      };
    }
  }, [
    anbMastercardCheckoutCancelCallBack,
    anbMastercardCheckoutBaseUrl,
    anbMastercardCheckoutVersion,
    isMasterCardAvailable,
  ]);

  React.useEffect(() => {
    if (!openDepositDialog) {
      setOpenDepositDialog(false);
      setResultIndicator(undefined);
      setSuccessIndicator(undefined);
      setHyperPayCheckoutId('');
      setError('');
      setIsLoading(false);
      setTimeout(() => {
        // delay showSuccessfulScreen as the deposit content is shown for a short time before closing the modal
        setShowSuccessfulScreen(false);
        setShowHyperPayProcessingScreen(false);
      }, 10);
    }
  }, [openDepositDialog]);

  return {
    openDepositDialog,
    onCloseDepositDialog,
    isApplePayAvailable,
    isMasterCardAvailable,
    onSuccessButtonClick,
    showSuccessfulScreen,
    showFailureScreen,
    showHyperPayProcessingScreen,
    hyperPayCheckoutId,
    closeDialog,
    error: openDepositDialog ? '' : tApiError(error || depositData.error),
    isLoading: isLoading || depositData.isLoading,
    onDeposit,
  };
};

export default useHyperPay;
